<template>
    <div class="tabs_vue">
        <div class="title_and_ul">
            <div class="title_block">
                <div @click="checkTab(1)" class="title" :class="{active: tab === 1}">
                    <h4>Ваш выбор - “мокрый фасад”</h4>
                    <span>в исполнении профессиональной компании-дилера Baumit, если для Вас важны:</span>
                </div>
                <div @click="checkTab(2)" class="title" :class="{active: tab === 2}">
                    <h4>Вам подойдут решения</h4>
                    <span> от множества компаний на основе штукатурки и других бюджетных материалов, если:</span>
                </div>

            </div>
            <div class="ul_block">
                <div v-show="(tab === 1)">
                    <ul>
                        <li>Неограниченные возможности для дизайна фасадов</li>
                        <li>Эксклюзивность и индивидуальный подход</li>
                        <li>Экологичность материалов</li>
                        <li>Здоровый микроклимат внутри помещения</li>
                        <li>Использование лучших мировых технологий для своего дома</li>
                        <li>Получить безупречный результат в срок</li>
                    </ul>
                    <popupbutton title="Это ровно то, что я ищу"></popupbutton>
                </div>
                <div v-show="(tab === 2)">
                    <ul>
                        <li>Вы не претендуете на эксклюзивность, а хотите “как у всех”</li>
                        <li>Вы хотите сэкономить на внешнем виде здания.</li>
                        <li>Вы не готовы платить за высокое качество материалов</li>
                        <li>Вас не смущают риски нарушения технологии монтажа</li>
                        <li>Вы готовы переложить гарантийные обязательства на себя</li>
                    </ul>
                    <popupbutton title="нет, я выбираю Baumit"></popupbutton>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { bus } from '../bus';
    export default {
        data(){
            return{
                tab: 1,

            }
        },
        methods:{
            checkTab(id){
                this.tab = id;
            }
        },
        mounted() {

        }
    }
</script>
<style lang="scss">
.tabs_vue{
    .title_block{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        .title{
            margin-bottom: 1rem;
            background: #eee;
            padding: 1rem;
            font-size: 0.6rem;
            border-radius: 15px;
            line-height: .7rem;
            &.active{
                background: #ee7f1a;
                color: #fff;
            }
            h4{
                margin-bottom: .5rem;
                font-size: .9rem;
            }
        }
    }
    .ul_block{
        ul{
            padding-left: 15px;
        }
    }
}
</style>
