<template>
    <button @click="openPopup" :class="`btn ${classbtn}`">{{title}}</button>
</template>

<script>
import { bus } from '../bus';
    export default {
        props:['title', 'classbtn'],
        data(){
            return{

            }
        },
        methods:{
            openPopup(){
                bus.$emit('openpupup', {
                    'open': true
                });
            }
        },
        mounted() {

        }
    }
</script>
<style lang="scss">

</style>
