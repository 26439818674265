<template>
    <div class="footer_form">

                <masked-input mask="\+\7 (111) 111-11-11"
                              v-model="phone"
                              @input="rawVal = arguments[1]"
                              id="exampleInputPhone"
                              class="input"
                              :class="{ error : error_phone }"
                              placeholder="Номер телефона (Обязательно)"
                />
                <input v-model="email" type="text" class="input" placeholder="Ваш e-mail" :class="{error: error_email}" />
                <input v-model="text" type="text" class="input" placeholder="Сообщение" />
                <div class="btn_area padding_top_2 right">
                    <button @click.prevent="sendForm" class="btn btn-burgundy">Отправить</button>
                </div>

    </div>
</template>

<script>
import { bus } from '../bus';
import MaskedInput from 'vue-masked-input';
    export default {
        data(){
            return{
                openpopup: false,
                loading: false,
                title: '',
                name: '',
                phone: '',
                email: '',
                text: '',
                titleform: '',
                giperlink: '',
                descriptionform: false,
                error_name: false,
                error_phone: false,
                error_email: false

            }
        },
        methods:{
            sendForm(){
                if( this.phone.length > 0 && this.email.length > 0){
                    this.error_phone = false;
                    if(this.phone.split('_').length > 1){
                        this.error_phone = 'Проверьте правильность введённого телефона';
                    }else{
                        this.loading = true;
                        $.ajax({
                            url: '/sendcontactmail',
                            type: "POST",
                            headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            data: {
                                'title': 'Форма из footer',
                                'phone': this.phone,
                                'email': this.email,
                                'text': this.text,
                                'token': document.querySelector('[name="csrf-token"]').getAttribute('content')
                            }
                        }).done(( res ) => {

                            let resdata = JSON.parse(res);

                            if(resdata.success){

                                bus.$emit('openpupup', {
                                    'open': true,
                                    'type': 'message',
                                    'title': resdata.title,
                                    'description': resdata.description,
                                });

                                // this.loading = false;
                                // this.titleform = resdata.title;
                                // this.descriptionform = resdata.description;
                                setTimeout(()=>{

                                    this.phone = '';
                                    this.email = '';
                                    this.text = '';

                                    bus.$emit('openpupup', {
                                        'open': false,
                                        'type': 'form',
                                        'title': 'У Вас остались вопросы?',
                                        'description': 'Оставьте номер телефона для обратного звонка.',
                                    });
                                    $('body').removeClass('hiddenoverflow');
                                }, 4000);
                            }
                        });
                    }



                }else{
                    // if(this.name.length <= 0){
                    //     this.error_n = 'Обязательное поле';
                    // }else{
                    //     this.error_name = false;
                    // }
                    if(this.phone.length <= 0){
                        this.error_phone = 'Обязательное поле';
                    }else{
                        this.error_phone = false;
                    }
                    if(this.email.length <= 0){
                        this.error_email = 'Обязательное поле';
                    }else{
                        this.error_email = false;
                    }
                }
            }
        },
        mounted() {

        },
        components:{
            MaskedInput
        },
    }
</script>
<style lang="scss">
.input{
    margin-top: 1rem;
    background: #2f3138;
    width: 100%;
    border-bottom: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: .5rem 0;
    color: #fff;
    outline: none;
    &.error{
        border-color: red;
    }
}
</style>
