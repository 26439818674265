<template>

    <div class="mobile_panel_block" :class="{'open':open}">

        <div class="mobile_panel_content">

            <div class="mobile_panel_content__header after-menu-mobile menu-mobile-line">
                <div class="menu-mobile-line">
                    <a href="/">
                        <img :src="urllogo" class="img-responsive logo">
                    </a>
                </div>
            </div>

            <div class="mobile_panel_content__center text-center">
                <div class="phoneinfo_header">
                    <div class="address_and_phone">
                        <b><a :href="`tel://${phone_m}`">{{ phone }}</a></b>
                        <p><a href="mailto:info@fasadmaker.ru">info@fasadmaker.ru</a></p>
                    </div>
                </div>
                <ul class="nav navbar-nav">
                    <li class="nav-item">
                        <a @click="closeMenu" href="#screen_2">Технологии</a>
                    </li>
                    <li class="nav-item">
                        <a @click="closeMenu" href="#screen_4">Почему Baumit?</a>
                    </li>
                    <li class="nav-item">
                        <a @click="closeMenu" href="#screen_8">Ценности</a>
                    </li>
                    <li class="nav-item">
                        <a @click="closeMenu" href="#screen_10">Этапы работ</a>
                    </li>
                    <li class="nav-item">
                        <a @click="closeMenu" href="#screen_pay">Купить материалы</a>
                    </li>
                    <li class="nav-item">
                        <a @click="closeMenu" href="#footer">Контакты</a>
                    </li>
                </ul>


            </div>
            <div class="mobile_panel_content__footer social_icon">
<!--                <div class="messengers messengers_footer">-->
<!--                    <a onclick="yaCounter52124575.reachGoal('whatsapp')" :href="whatsapp">-->
<!--                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" class="svg-inline&#45;&#45;fa fa-whatsapp fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>-->
<!--                    </a>-->
<!--                    <a onclick="yaCounter52124575.reachGoal('viber')" :href="viber">-->
<!--                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="viber" class="svg-inline&#45;&#45;fa fa-viber fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"></path></svg>-->
<!--                    </a>-->
<!--                    <a onclick="yaCounter52124575.reachGoal('telegram')" :href="telegram">-->
<!--                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="telegram-plane" class="svg-inline&#45;&#45;fa fa-telegram-plane fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>-->
<!--                    </a>-->
<!--                </div>-->
            </div>

        </div>
        <div class="mobile-panel">

            <div class="mobile-panel-item">
                <div class="panel_icon">
                    <a :href="`tel://${phone_m}`">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" class="svg-inline--fa fa-phone fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                    </a>
                </div>
            </div>


            <!--            <div class="mobile-panel-item">-->
<!--                <div class="messengers">-->
<!--                    <div class="panel_icon">-->

<!--                        <div v-if="opensee" @click="closeSeePanel" class="hamburger-box open">-->
<!--                            <div class="bun top"></div>-->
<!--                            <div class="meat"></div>-->
<!--                            <div class="bun bottom"></div>-->
<!--                        </div>-->

<!--                        <svg @click="openSeePanel" v-else aria-hidden="true" focusable="false" data-prefix="fas" data-icon="eye" class="svg-inline&#45;&#45;fa fa-eye fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

            <div class="mobile-panel-item">
                <div class="to-x" @click="openPanel">
                    <div class="hamburger-box" :class="{'open':open}">
                        <div class="bun top"></div>
                        <div class="meat"></div>
                        <div class="bun bottom"></div>
                    </div>
                </div>
            </div>
            <div class="mobile-panel-item">
                <div class="messengers">
                    <div @click="openPopupForm" class="panel_icon">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" class="svg-inline--fa fa-envelope fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                    </div>

                </div>
            </div>

        </div>
    </div>

</template>

<script>

import { bus } from '../bus';

export default {
    props: ['phone', 'phone_m', 'viber', 'icon_viber', 'telegram', 'icon_telegram', 'icon_whatsapp', 'whatsapp', 'urllogo', 'iconinstagram', 'iconvk', 'iconfacebook'],

    data(){
        return {
            open: false,
            openmess: false,
            openzayavka: false,
            opensee: false
        }
    },
    methods: {
        openSubMenu(a){
            console.log(a);
            a.classList.add("open");
        },
        openSeePanel(){
            bus.$emit('seepanel', {
                'open': true
            });
            this.open = false;
            this.opensee = true;
            this.openmess = false;
            this.openzayavka = false;
            bus.$emit('openpopup', {
                'open': false,
                'title': "Заявка с сайта"
            });
        },
        closeSeePanel(){
            bus.$emit('seepanel', {
                'open': false
            });
            this.opensee = false;
        },
        openPanel(){
            bus.$emit('openpopup', false);
            this.open = !this.open;
            this.openmess = false;
            this.openzayavka = false;
            this.opensee = false;
            this.closeSeePanel();
        },
        openPopupForm(){

            bus.$emit('openpupup', {
                'open': true,
                'type': 'form',
                'title': 'У Вас остались вопросы?',
                'description': 'Оставьте номер телефона для обратного звонка.',
            });

            // this.openmess = !this.openmess;

            this.open = false;
            // this.opensee =   false;
            // this.closeSeePanel();

            // if(this.openmess){
            //     bus.$emit('openpupup', {
            //         'open': true,
            //         'type': 'form',
            //         'title': 'У Вас остались вопросы?',
            //         'description': 'Оставьте номер телефона для обратного звонка.',
            //     });
            // }else{
            //     bus.$emit('openpupup', {
            //         'open': false,
            //         'type': 'form',
            //         'title': 'У Вас остались вопросы?',
            //         'description': 'Оставьте номер телефона для обратного звонка.',
            //     });
            // }


        },
        closeMenu(){
            this.open = false;
            this.opensee = false;
            bus.$emit('burger', false);
        }
    },
    components: {

    },
    computed:{

    },
    mounted() {
        bus.$on('openpanel', (data) => {
            this.open = false;
        });
        bus.$on('closepopup', (data)=>{
            this.openzayavka = false;
        });
        bus.$on('closbuttonsee', (data)=>{
            this.opensee = false;
        });
        $('body').on('click', '.plus_menu', function() {
            if($(this).hasClass('open')){
                $(this).removeClass('open');
                $(this).text('+');
            }else{
                $(this).addClass('open');
                $(this).text('-');
            }
        });
    },
    created() {

    },
    updated(){

    }
}
</script>
<style scoped lang="scss">
    .mobile_panel_block{
        z-index: 9999;
        position: relative;
        color: #fff;
        display: none;
        a {
            color: #fff;
        }
        &.open{
            .mobile_panel_content{
                display: grid;

            }
        }
    }
    .mobile_panel_content{
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        position: fixed;
        background: var(--color-main);
        top: 0;
        left: 0;
        width: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 69px);
        flex-direction: column;
        grid-template-rows: auto 1fr 65px;
        padding-bottom: 70px;
        .mobile_panel_content__header{
            padding: 20px;
            .logo {
                width: 100%;
            }
        }
        .mobile_panel_content__center{
            .phoneinfo_header {
                margin-bottom: 1rem;
                margin-top: 1rem;
                .address_and_phone{
                    a{
                        font-size: 1.3rem;
                        text-decoration: none;
                    }
                }
            }
        }
        .social_icon{
            display: flex;
            justify-content: center;
            margin-top: 0;
            .social_icon__item {
                margin-right: 10px;
                margin-left: 10px;
            }
        }
        .phoneinfo_header{
            display: flex;
            justify-content: center;
        }
        .navbar-nav > li{
            text-align: center;
            padding: .5rem 0;
            & > a {
                line-height: 20px;
                font-weight: bold;
            }
        }
        .navbar-nav > li > a:hover{
            color: var(--main-color);
        }

        .nav.navbar-nav{
            display: flex;
            justify-content: center;
            width: 100%;
            border-right: 0;
            padding: 0;
            height: auto;
        }
        .phoneinfo_header{
            margin-bottom: 2rem;
        }
        a.icon-cart {
            color: #fff;
            position: relative;
            & span.cart-count {
                background: var(--main-color);
                color: #fff;
                width: 25px;
                display: flex;
                height: 25px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                border: 2px solid;
                position: absolute;
                top: -6px;
                right: -11px;
            }
        }

    }
    .mobile-panel {
        background: var(--color-main);
        display: none;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        height: 70px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999999;
        .mobile-panel-item {
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                color: var(--color-main);
            }
        }
    }
    .fil0 {fill:var(--main-color)}
    .messengers{
        display: flex;
        position: relative;
        a{
            svg{
                width: 30px;
                height: 30px;
                color: var(--color-main);
            }
        }
    }
    .messengers_icon {
        width: 50px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        background-color: #fff;
        border-radius: 50%;
        padding: 7px 10px;
        svg{
            width: 30px;
            height: 30px;
        }
    }
    .menu-mobile-line {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;
        .logo{
            width: 264px;
        }
    }
    .to-x {
        display: flex;
        align-items: center;
        justify-content: center;
        .hamburger-box {
            width: 50px;
            height: 50px;
            display: block;
            vertical-align: middle;
            position: relative;
            cursor: pointer;
            margin: 0;
            padding-top: 6px;
            padding-bottom: 6px;
            border-radius: 100%;
            transition: all 200ms ease-in-out;
            background: #fff;
            .top {
                top: 16px;
            }
            .meat {
                top: 50%;
                margin-top: -1px;
            }
            .bottom {
                bottom: 16px;
            }
            &.open{
                .top{
                    transition: all 300ms ease-in-out;
                    background-color: #161f1e;
                    transform: rotate(-45deg);
                    width: 20px;
                    top: 50%;
                    margin-top: 0px;
                    margin-left: -10px;
                }
                .meat {
                    width: 0px;
                    margin-left: 0px;
                    background-color: #161f1e;
                }
                .bottom {
                    transition: all 300ms ease-in-out;
                    background-color: #161f1e;
                    transform: rotate(45deg);
                    width: 20px;
                    bottom: 50%;
                    margin-bottom: -2px;
                    margin-left: -10px;
                }
            }
        }
    }
    .to-x .hamburger-box .bun,
    .to-x .hamburger-box .meat {
        width: 23px;
        height: 2px;
        background-color: var(--color-main);
        position: absolute;
        left: 50%;
        margin-left: -12px;
    }
    .call {
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .panel_icon{
        cursor: pointer;
        background: #fff;
        width: 50px;
        height: 50px;
        border-radius: 40px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 40px;
            height: 40px;
            color: var(--color-main);
            padding: 5px;
            min-width: 40px;
        }
        &.open{
            svg{
                display: none;
            }
            &:before{
                content: "";
                width: 20px;
                height: 2px;
                display: block;
                position: absolute;
                left: 15px;
                top: 24px;
                background: var(--color-black);
                transform: rotate(45deg);
            }
            &:after{
                content: "";
                width: 20px;
                height: 2px;
                display: block;
                position: absolute;
                left: 15px;
                top: 24px;
                background: var(--color-black);
                transform: rotate(-45deg);
            }
        }
    }
    .messengers_block {
        flex-direction: column;
        position: absolute;
        bottom: 60px;
        /*background: #eee;*/
        .messengers_block_item {
            height: 55px;
            a {
                display: flex;
                align-items: center;
                color: var(--color-black);
                text-decoration: none;
                font-weight: bold;
                .messengers_icon_item{
                    width: 50px;
                    height: 50px;
                    svg {
                        width: 50px;
                        height: 50px;
                        background: #fff;
                        padding: 5px;
                    }
                }
                span{
                    margin-right: 1rem;
                }
            }
        }
    }
    .messengers_icon.open + .messengers_block {
        display: flex;
    }
    .mess_dt_item {
        display: block;
        bottom: 0;
        background: #ffffff;
        height: 50px;
        position: relative;
        /*&.whatsapp {*/
        /*    background-image: url(../../images/icon/wa.png);*/
        /*}*/
        /*&.viber {*/
        /*    background-image: url(../../images/icon/viber.png);*/
        /*}*/
    }
    .messengers_footer{
        margin-top: 1rem;
        a{
            margin: 0.8rem;
            background: #fff;
            padding: 10px;
            border-radius: 50%;
            img{
                width: 40px;
            }
        }
    }
    .plus_menu{
        background: #fff;
        color: #000;
        padding: 0;
        height: 20px;
        line-height: 1rem;
        width: 20px;
        font-weight: bold;
        text-align: center;
        outline: 0;
        margin-left: 10px;
        position: absolute;
        &:hover{
            color: #000;
            outline: 0;
        }
        &.open + .sub-menu.mega-light{
            display: block;
        }
    }
    .sub-menu.mega-light{
        display: none;
        list-style: none;
        margin-top: 1rem;
        margin-bottom: 2rem;
        li{
            a{
                padding: 5px 0;
                display: block;
            }
        }
    }
    .mobile_panel_content{
        overflow: auto;
    }

    @media(max-width: 1240px){
        .mobile-panel{
            display: grid;
        }
        .messengers.messengers_dt{
            display: none;
        }
        #scroll{
            display: none !important;
        }
    }
    @media(max-width: 991px){
        .mobile_panel_block{
            display: block !important;
        }
    }
    @media(max-width: 321px){

        .mobile_panel_content{

            .mobile_panel_content__center{
                .phoneinfo_header {
                    display: none;
                }
                .nav.navbar-nav {
                    margin-top: 2rem;
                }
            }

        }
    }
</style>
