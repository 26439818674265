<template>
    <div  v-if="openpopup" class="popup">
        <div @click="closePopup" class="popup_fon"></div>
        <div class="popup_content">
            <div class="colors_left">
                <div class="colors_left_item"></div>
                <div class="colors_left_item"></div>
                <div class="colors_left_item"></div>
                <div class="colors_left_item"></div>
            </div>
            <div class="popup_content_form">
                <div @click="closePopup" class="popup_content_form_close"></div>

                <h2>{{titleform}}</h2>
                <p>{{descriptionform}}</p>

                <div v-if="(type === 'form')">
                    <masked-input mask="\+\7 (111) 111-11-11"
                                  v-model="phone"
                                  @input="rawVal = arguments[1]"
                                  id="exampleInputPhone"
                                  class="form_phone"
                                  :class="{ error : error_phone }"
                                  placeholder="Номер телефона (Обязательно)"
                    />
                    <div class="btn_area padding_top_2 right">
                        <button @click.prvent="sendForm" class="btn btn-orange">Отправить</button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '../bus';
import MaskedInput from 'vue-masked-input';
    export default {
        data(){
            return{
                openpopup: false,
                type: 'form',
                phone: '',
                error_phone: '',
                titleform: 'У Вас остались вопросы?',
                descriptionform: 'Оставьте номер телефона для обратного звонка.'

            }
        },
        methods:{
            closePopup(){
                this.openpopup = false
            },
            sendForm(){
                if( this.phone.length > 0){
                    this.error_phone = false;
                    if(this.phone.split('_').length > 1){
                        this.error_phone = 'Проверьте правильность введённого телефона';
                    }else{
                        this.loading = true;
                        $.ajax({
                            url: '/sendmail',
                            type: "POST",
                            headers: {
                                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                            },
                            data: {
                                'title': 'Заявка с сайта',
                                'phone': this.phone,
                                'token': document.querySelector('[name="csrf-token"]').getAttribute('content')
                            }
                        }).done(( res ) => {
                            let resdata = JSON.parse(res);
                            if(resdata.success){

                                this.titleform = resdata.title;
                                this.descriptionform =  resdata.description;
                                this.type = 'request';

                                setTimeout(()=>{

                                    this.titleform = 'У Вас остались вопросы?';
                                    this.descriptionform =  'Оставьте номер телефона для обратного звонка.';
                                    this.type = 'form';
                                    this.phone = '';
                                    this.openpopup = false;

                                    $('body').removeClass('hiddenoverflow');
                                }, 3000);

                            }
                        });
                    }



                }else{

                    if(this.phone.length <= 0){
                        this.error_phone = 'Обязательное поле';
                    }else{
                        this.error_phone = false;
                    }

                }
            }
        },
        mounted() {
            bus.$on('openpupup', (data) => {
                console.log('openpupup',data);
                if(data.open){
                    this.openpopup = true;
                    if( data.title ){
                        this.titleform = data.title;
                    }
                    if(data.description){
                        this.descriptionform =  data.description;
                    }
                    if(data.type){
                        this.type = data.type;
                    }
                }else{
                    this.openpopup = false;
                    if( data.title ){
                        this.titleform = data.title;
                    }
                    if(data.descriptionform){
                        this.descriptionform =  data.description;
                    }
                    if(data.type){
                        this.type = data.type;
                    }
                }
            });
        },
        components:{
            MaskedInput
        },
    }
</script>
<style lang="scss">
.popup{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.popup_fon{
    background: #0000008a;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.popup_content{
    background: var(--color-black);
    display: flex;
    color: #fff;
    z-index: 2;
    .colors_left{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 7px;
    }
}
.popup_content_form{
    position: relative;
    padding: 5rem;
    .popup_content_form_close{
        position: absolute;
        width: 40px;
        height: 40px;
        right: 20px;
        top: 20px;
        cursor: pointer;
        &:before{
            content: "";
            width: 40px;
            height: 2px;
            background: #fff;
            position: absolute;
            transform: rotate(-45deg);
            top: 19px;
        }
        &:after{
            content: "";
            width: 40px;
            height: 2px;
            background: #fff;
            position: absolute;
            transform: rotate(45deg);
            top: 19px;
        }
    }
}
.form_phone{
    margin-top: 1rem;
    background: #2f3138;
    width: 100%;
    border-bottom: 1px solid #fff;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: .5rem;
    color: #fff;
    outline: none;
}
</style>
